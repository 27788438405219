<template>
    <div class="main" v-html="content"></div>
</template>
<script>
export default {
    props:{
        content:String
    }
}
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
    .main{
        width: 990px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 0 auto;
        padding: 50px;
        min-height: 900px;
        border-radius: 6px;
        background-color: white;
    }

/deep/ .tittle {
    font-size: 36px;
    margin-bottom: 40px;
}

/deep/ .content {
    width: 710px;

    p {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 40px;
    }

    margin-bottom: 20px;
}

/deep/ .bottom {
    align-self: flex-end;

    .author {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .date {
        font-size: 20px;
    }
}

/deep/ li::before {
    content: "";
    width: 6px !important;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    background: #000;
    vertical-align: middle;
    margin-right: 14px;
}

/deep/ li {
    font-size: 20px;
    line-height: 30px;
}

/deep/ a {
    color: #3370ff !important;

    &:hover {
        text-decoration: underline !important;
        color: #3370ff !important;
    }
}
</style>
