export default [
    {
        title:'关于新增DEX数据的公告',
        content:`
        <h1 class="tittle">关于新增DEX数据的公告</h1>
        <div class="content">
            <p>尊敬的蜜蜂查数据中心用户:</p>
            <p>为了满足用户关于DEX的数据需求，我们将于2021年12月1日更新以下DEX数据：</p>
            <p>
                <ul>
                    <li>Balancer: <a href="https://balancer.fi/" rel="noopener noreferrer nofollow" target="_blank">https://balancer.fi/</a></li>
                    <li>DODO: <a href="https://dodoex.io/zh" rel="noopener noreferrer nofollow" target="_blank">https://dodoex.io/zh</a></li>
                    <li>dYdX: <a href="https://dydx.exchange/" rel="noopener noreferrer nofollow" target="_blank">https://dydx.exchange/</a></li>
                    <li>Orca: <a href="https://www.orca.so/pools" rel="noopener noreferrer nofollow" target="_blank">https://www.orca.so/pools</a></li>
                    <li>ShibaSwap: <a href="https://shibaswap.com/#/" rel="noopener noreferrer nofollow" target="_blank">https://shibaswap.com/#/</a></li>
                    <li>SpookySwap: <a href="https://spookyswap.finance/" rel="noopener noreferrer nofollow" target="_blank">https://spookyswap.finance/</a></li>
                    <li>Tokenlon: <a href="https://tokenlon.im/instant " rel="noopener noreferrer nofollow" target="_blank">https://tokenlon.im/instant </a></li>
                    <li>TraderJoe: <a href="https://traderjoexyz.com/#/home" rel="noopener noreferrer nofollow" target="_blank">https://traderjoexyz.com/#/home</a></li>
                    <li>ZKSwap: <a href="https://zks.org/en" rel="noopener noreferrer nofollow" target="_blank">https://zks.org/en</a></li>
                    <li>QuickSwap: <a href="https://quickswap.exchange/#/" rel="noopener noreferrer nofollow" target="_blank">https://quickswap.exchange/#/</a></li>
                    <li>Raydium: <a href="https://raydium.io/" rel="noopener noreferrer nofollow" target="_blank">https://raydium.io/</a></li>
                </ul>
            </p>
            <p>如您有疑问，请联系客服或提交表单联系我们。</p>
            <p>感谢您一路的陪伴，蜜蜂查将持续为您提供更优秀的产品和更优质的服务！</p>
            <p>特此公告</p>
        </div>
        <div class="bottom">
            <div class="author">蜜蜂查团队</div>
            <div class="date">2021年11月30日</div>
        </div>
        `        
    },
    {
        title:'关于调整体验版使用次数的公告',
        content:`
        <h1 class="tittle">关于调整体验版使用次数的公告</h1>
        <div class="content">
            <p>尊敬的蜜蜂查数据中心用户:</p>
            <p>为了确保您使用API服务的稳定性以及提高我们的服务质量，我们将于2021年8月1日对体验版套餐进行调整，其他版本套餐保持不变。现将调整内容公告如下：</p>
            <p>
                <ul>
                    <li>调用次数由10,000次/月调整至1,000次/月</li>
                    <li>同个账户体验次数调整至1次</li>
                    <li>体验版仅限新用户使用</li>
                </ul>
            </p>
            <p>如您有疑问，请联系客服或提交表单联系我们。</p>
            <p>感谢您一路的陪伴，蜜蜂查将持续为您提供更优秀的产品和更优质的服务！</p>
            <p>特此公告</p>
        </div>
        <div class="bottom">
            <div class="author">蜜蜂查团队</div>
            <div class="date">2021年7月28日</div>
        </div>
        `        
    },
    {
        title:'关于调整套餐定价的公告',
        content:`
        <h1 class="tittle">关于调整套餐定价的公告</h1>
        <div class="content">
            <p>尊敬的蜜蜂查数据中心用户:</p>
            <p>为了适应大陆用户的支付习惯，蜜蜂查数据中心将API服务的套餐价格由美元定价改为人民币定价。同时，为了方便用户计算金额，我们对套餐价格做了微调，微调范围为-5%~+5%。历史订单中原价仍以美元计价显示，应付金额由原价乘以当时美元兑人民币实时汇率计算显示。</p>
            <p>如您有疑问，请联系客服或提交表单联系我们。</p>
            <p>感谢您一路的陪伴，蜜蜂查将持续为您提供更优秀的产品和更优质的服务！</p>
        </div>
        <div class="bottom">
            <div class="author">蜜蜂查团队</div>
            <div class="date">2021年2月5日</div>
        </div>
        `
    }
]