<template>
    <div class="main-block">
        <div class="left">
            <div class="block" v-for="(m,i) in announcementConfig" :key="i" @click="tabClick(i)" :class="{ active: active == i }">
                {{m.title}}
            </div>
        </div>
        <div class="right">
            <Announcement v-if="active == i" :content="m.content" v-for="(m,i) in announcementConfig" :key="i"></Announcement>
        </div>
    </div>
</template>

<script>
import Announcement from '../components/announcement/index';
import announcementConfig from '../utils/announcementConfig';
export default {
    data() {
        return {
            active: 0,
            publicPath: process.env.BASE_URL,
            announcementConfig
        }
    },
    components: {
        Announcement
    },
    methods: {
        tabClick(i) {
            this.active = i;
        },
    },
}
</script>

<style lang="less" scoped>
@import '~assets/css/theme.less';

.main-block {
    padding-top: 20px;
    display: flex;
    width: @container;
    margin: 0 auto;
    .left {
        width: 280px;
        font-size: 20px;
        .block {
            font-size: 16px;
            text-align: left;
            padding-left: 20px;
            height: 60px;
            line-height: 60px;
            width: 280px;
            background-color: white;
            cursor: pointer;
            border-bottom: 1px solid #F1F1F5;
            &:hover {
                color: #ffa800;
            }
        }
        .active {
            color: #ffa800;
        }
    }
    .right {
        margin-left: 10px;
    }
}
</style>